import { DefaultHits } from "@wsui/algolia";
import { createTheme, Icon, withDefaultProps, Button, Link } from "@wsui/base";
import { EventAttributeList } from "@wsui/municipio";
import React from "react";

import BreadcrumbsDescription from "../BreadcrumbsDescription.jsx";
import EventArchiveHit from "../EventArchiveHit.jsx";
import EventArchiveHits from "../EventArchiveHits.jsx";
import HeroBillboardModule from "../HeroBillboardModule.jsx";
import ResearchGroupArchiveHit from "../ResearchGroupArchiveHit.jsx";

import "./index.css";

export default createTheme({
  typography: {
    fonts: {
      // Inter: "/fonts/Inter.var.woff2",
      "Roboto Flex": {
        src: 'url("/fonts/RobotoFlex-VariableFont.ttf") format("truetype")',
      },
    },
    fontFamily: "Roboto Flex, Helvetica, sans-serif",
    maxLineLength: "70ch",
    variants: {
      h1: {
        fontSize: [7, 11.5],
        lineHeight: [9.25, 14],
        fontWeight: 600,
        marginBlockStart: 12.5,
        marginBlockEnd: 8.75,
        fontVariationSettings: "'wdth' 90",
      },
      h2: {
        fontSize: [5.5, 8.5],
        lineHeight: [8, 11.5],
        fontWeight: 600,
        marginBlockStart: 12.5,
        marginBlockEnd: 5,
        fontVariationSettings: "'wdth' 90",
      },
      h3: {
        fontSize: [4.5, 5],
        lineHeight: [7, 8.75],
        fontWeight: 600,
        marginBlockStart: 12.5,
        marginBlockEnd: 5,
        fontVariationSettings: "'wdth' 90",
      },
      h4: {
        fontSize: 4,
        lineHeight: 6.5,
        fontWeight: 600,
        marginBlockStart: 12.5,
        marginBlockEnd: 5,
        fontVariationSettings: "'wdth' 90",
      },
      h5: {
        fontSize: 4,
        lineHeight: 6.5,
        fontWeight: 600,
        marginBlockStart: 12.5,
        marginBlockEnd: 5,
        fontVariationSettings: "'wdth' 90",
      },
      h6: {
        fontSize: 4,
        lineHeight: 6.5,
        fontWeight: 600,
        marginBlockStart: 12.5,
        marginBlockEnd: 5,
        fontVariationSettings: "'wdth' 90",
      },
      body: {
        fontSize: [4.5, 5],
        lineHeight: [7, 8.75],
        fontWeight: 400,
        marginBlockEnd: 7.5,
      },
      preamble: {
        fontSize: [4.5, 5],
        lineHeight: [7, 8.75],
        fontWeight: 600,
        marginBlockEnd: 7.5,
      },
      description: {
        fontSize: 4,
        lineHeight: 6.5,
        fontWeight: 400,
      },
      quote: {
        fontSize: [5.5, 8.5],
        lineHeight: [8, 11.25],
        marginBlockEnd: 7.5,
        fontStyle: "italic",
      },
      caption: {
        fontSize: [3.75, 4],
        lineHeight: [5, 6.25],
        fontWeight: 700,
        textTransform: "uppercase",
      },
    },
  },
  colors: {
    verdant: "#44A474",
    evergreen: "#00543E",
    ever: "#003D2D",
    primary: "verdant",
    secondary: "evergreen",
    gray: "#F0F0F0",
    darkGray: "#7B7B7B",
    shade: "#1A1A1A",
    heavyMist: "#E9E9E9",
    lightMist: "#F7F7F7",
    seed: "#D4F4D4",
    sand: "#FBF3DF",
    wind: "#DAE9E4",
    border: "gray.200",
    white: { main: "#ffffff", hover: "+25" },
  },
  linkColors: ["evergreen", "white"],
  components: {
    Breadcrumbs: {
      defaultProps: {
        hideDescription: false,
        gap: 1,
        descriptionGap: 1,
        components: {
          Description: BreadcrumbsDescription,
        },
      },
    },
    ConfirmDialog: {
      defaultProps: {
        confirmButtonProps: {
          append: null,
        },
      },
    },
    TreeMenuItem: {
      defaultProps: {
        color: "evergreen",
        hoverColor: "ever",
      },
    },
    TreeMenuItemHeader: {
      defaultProps: {
        activeColor: "evergreen",
      },
    },
    TreeMenuLink: {
      defaultProps: {
        fontWeight: 700,
        fontSize: "16px",
        padding: "1.25rem",
      },
    },
    TreeMenuToggleButton: {
      defaultProps: {
        offset: "1.25rem",
      },
    },
    SlidePanelAction: {
      defaultProps: {
        color: "evergreen",
      },
    },
    Button: {
      defaultProps: {
        variant: "default",
        style: {
          paddingBlock: 2.5,
          paddingInline: 5,
          borderRadius: 1,
          fontWeight: 700,
          transition: "0.22s ease",
          gap: "0.625rem",
        },
      },
      variants: {
        primary: {
          append: <Icon name="arrow-right" size="5" color="white" />,
          style: {
            backgroundColor: "primary",
            color: "white",
          },
          hoverStyle: {
            backgroundColor: "evergreen",
            color: "white",
          },
          activeStyle: {
            backgroundColor: "ever",
            color: "white",
          },
        },
        secondary: {
          style: {
            backgroundColor: "sand",
            color: "evergreen",
            ".wsui-icon": {
              color: "wind",
            },
          },
          hoverStyle: {
            backgroundColor: "evergreen",
            color: "white",
          },
          activeStyle: {
            backgroundColor: "ever",
            color: "white",
          },
        },
        default: {
          style: {
            backgroundColor: "sand",
            color: "shade",
          },
          hoverStyle: {
            backgroundColor: "evergreen",
            color: "white",
          },
          activeStyle: {
            backgroundColor: "ever",
            color: "white",
          },
        },
        shortcut: {
          style: {
            paddingBlock: [4, 4],
            paddingInline: [6, 6],
            backgroundColor: "sand",
            color: "shade",
            borderRadius: 2.5,
            "--icon-color": "evergreen",
          },
          hoverStyle: {
            backgroundColor: "evergreen",
            color: "white",
            "--icon-color": "white",
          },
          activeStyle: {
            backgroundColor: "ever",
            color: "white",
            "--icon-color": "white",
          },
        },
        toggle: {
          style: {
            color: "evergreen",
            backgroundColor: "white",
            fontWeight: 400,
            paddingBlock: 4,
            paddingInline: 5,

            "@media (min-width: 640px)": {
              color: "white",
              backgroundColor: "evergreen",
            },
          },
          hoverStyle: {
            backgroundColor: "ever",
            color: "white",
          },
          activeStyle: {
            backgroundColor: "evergreen",
            color: "white",
          },
        },
        outline: {
          style: {
            backgroundColor: "transparent",
            color: "evergreen",
            border: "2px solid",
            borderColor: "currentColor",
          },
        },
      },
    },
    Clickable: {
      defaultProps: {
        hideExternalIcon: true,
      },
    },
    Link: {
      defaultProps: {
        style: {
          textUnderlineOffset: "0.2em",
          textDecorationThickness: "1px",
        },
      },
    },
    Icon: {
      defaultProps: {
        className: "wsui-icon",
      },
    },
    Header: {
      defaultProps: {
        color: "white",
        backgroundColor: "evergreen",
        border: "heavyMist",
        boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05)",
        paddingBlock: [4, 8],
        disableHamburgerMenu: false,
        components: {
          HamburgerMenuToggle: withDefaultProps(Button, { variant: "toggle" }),
        },
      },
    },
    HeaderSearchField: {
      defaultProps: {
        padding: "1.3rem 2rem 1.3rem 2.875rem",
      },
    },
    HeaderMainMenu: {
      defaultProps: {
        style: {
          color: "evergreen",
          fontSize: 4.5,
          fontWeight: 700,
        },
      },
    },
    HeaderHelpMenu: {
      defaultProps: {
        style: {
          color: "white",
          backgroundColor: "evergreen",
          fontSize: 3.5,
          fontWeight: 600,
          padding: 3,
          lineHeight: 6,
        },
      },
    },
    Box: {
      defaultProps: {
        borderRadius: 1.25,
      },
    },
    Card: {
      defaultProps: {
        horizontalContentMaxWidth: 160,
      },
    },
    CardMeta: {
      defaultProps: {
        color: "darkGray",
      },
    },
    Footer: {
      defaultProps: {
        color: "evergreen",
        bottomColor: "white",
        justifyContent: "center",
        bannerMargin: 8,
      },
    },
    FooterMenuBlock: {
      defaultProps: {
        components: {
          Link,
        },
      },
    },
    Template: {
      transformProps: (props, { pageContext }) => {
        // Show outline nav by default on pages with text content
        if (
          pageContext?.hasPageContent &&
          ["page", "researchGroup"].includes(
            pageContext?.contentType?.graphqlSingleName,
          )
        ) {
          props.showOutlineNav = true;
        }
      },
      defaultProps: {
        defaultColspan: 8,
        breadcrumbsMarginEnd: 20,
        footerMargin: [10, 20],
      },
    },
    TearOffDate: {
      defaultProps: {
        color: "evergreen",
      },
    },
    WpCaption: {
      defaultProps: {
        borderRadius: 1.25,
      },
    },
    WpImage: {
      defaultProps: {
        borderRadius: 1.25,
      },
    },
    PageHeading: {
      defaultProps: {
        datesTextColor: "darkGray",
      },
    },
    MunicipioBillboardModuleController: {
      defaultProps: {
        components: { HeroBillboardModule },
      },
    },
    MunicipioHeroBillboardModule: {
      defaultProps: {
        contentElementMapping: { p: "body" },
        titleVariant: null,
      },
    },
    MunicipioImageModule: {
      defaultProps: {
        imageProps: { borderRadius: 1.25 },
      },
    },
    MunicipioPostsModule: {
      defaultProps: {
        itemColor: "gray.100",
        archiveLinkProps: {
          variant: "outline",
          append: <Icon name="arrow-right" size="5" />,
        },
      },
    },
    MunicipioPostsModuleMixedLayout: {
      defaultProps: {
        collapsedItemProps: {
          visibleFields: ["title", "date"],
        },
      },
    },
    MunicipioPostsModuleCardItem: {
      defaultProps: {
        metaProps: {
          style: {
            order: -1,
          },
        },
        dateFormat: {
          month: "short",
          day: "numeric",
          year: "numeric",
        },
        components: {
          Button: {
            variant: "primary",
          },
          Excerpt: {
            style: {
              color: "#1A1A1A",
            },
          },
        },
      },
    },
    ModularityArea: {
      defaultProps: {
        sectionPadding: 20,
        shouldMakePageSection: (context, defaultShouldMakePageSection) => {
          let { moduleRow } = context;
          if (defaultShouldMakePageSection(context)) {
            return true;
          }
          let module = moduleRow.modules[0];
          if (module.module.modBillboard?.format === "hero") {
            return false;
          }
        },
      },
    },
    PublicationsModule: {
      defaultProps: {
        style: {
          color: "shade",
          fontSize: 3.5,
          lineHeight: 5.5,
        },
        urlColor: "evergreen",
      },
    },
    AlgoliaHitsController: {
      defaultProps: {
        components: {
          ResearchGroupArchiveHits: withDefaultProps(DefaultHits, {
            divider: false,
            spacing: 0,
          }),
          EventArchiveHits,
        },
      },
    },
    AlgoliaDefaultHits: {
      defaultProps: {
        spacing: [5, 10],
      },
    },
    AlgoliaHit: {
      defaultProps: {
        titleProps: { variant: "h3" },
      },
    },
    AlgoliaDefaultHit: {
      defaultProps: {
        showPublishDate: true,
      },
    },
    AlgoliaResearchGroupArchiveHit: {
      transformProps: (props) => {
        props.color = props.index % 2 === 0 ? "gray" : "white";
      },
      defaultProps: {
        keepPadding: true,
      },
    },
    AlgoliaHitController: {
      defaultProps: {
        components: {
          ResearchGroupArchiveHit,
          EventArchiveHit,
        },
      },
    },
    ContentTypeTemplate: {
      transformProps: (props) => {
        if (
          props.pageContext?.contentType?.graphqlSingleName ===
            "researchGroup" ||
          props.pageContext?.contentType?.graphqlSingleName === "event"
        ) {
          props.defaultColspan = 12;
        }
      },
    },
    PostModule: {
      defaultProps: {
        normalizePostsModuleItems: (
          module,
          options,
          defaultNormalizePostsModuleItems,
        ) => {
          let items = defaultNormalizePostsModuleItems(module, options);
          const { Html } = options;
          items = items.map((item) => {
            let description = item.description;
            if (item.contentType === "event") {
              let {
                eventProperties: { eventOccasions, ...attributes },
              } = item;
              description = (
                <EventAttributeList
                  occasions={eventOccasions}
                  attributes={attributes}
                  renderAttributeValue={(value) => <Html>{value}</Html>}
                  compactOccasions
                  spacing={2}
                />
              );
            }
            return {
              ...item,
              description,
            };
          });
          return items;
        },
      },
    },
  },
});
