import { css, useTheme } from "@emotion/react";
import { Html } from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import {
  Card,
  CardContent,
  CardTitle,
  useThemeProps,
  Typography,
  TearOffDate,
  // Typography,
} from "@wsui/base";
import { EventAttributeList } from "@wsui/municipio";
import React, { Fragment } from "react";
// import { useTranslation } from "react-i18next";

export default function EventArchiveHit(props) {
  const theme = useTheme();
  props = useThemeProps({ props, name: "AlgoliaEventArchiveHit" });
  props = useThemeProps({ props, name: "AlgoliaHit" });
  let {
    hit,
    horizontalContentMaxWidth = "100%",
    hideImage,
    // eslint-disable-next-line no-unused-vars
    hitsOwnerState,
    titleProps = { variant: "h4" },
    ...restProps
  } = props;

  // const { t } = useTranslation();

  const {
    title,
    url,
    // text,
    // contentTypeName,
    image,
    eventProperties: { eventOccasions, ...eventAttributes },
  } = hit;

  console.log(hit);

  // let contentTypeLabel = t(`contentTypes.${contentTypeName}.name`, {
  //   count: 1,
  // });

  let mostRelevantOccasion = eventOccasions[0];

  return (
    <Card
      link={{ url }}
      title={title}
      image={hideImage ? undefined : image}
      mirrored
      mediaColspan={3}
      as="li"
      horizontalContentMaxWidth={horizontalContentMaxWidth}
      {...restProps}
      color="gray"
    >
      {({ hasImage }) => (
        <Fragment>
          {hasImage && !!mostRelevantOccasion && (
            <TearOffDate
              date={mostRelevantOccasion.startDate}
              css={css`
                position: absolute;
                top: 0;
                margin: ${theme.getLength(5)};
              `}
            />
          )}
          <CardContent>
            <div
              css={css`
                display: flex;
                row-gap: inherit;
                align-items: center;
                column-gap: ${theme.getLength(5)};
              `}
            >
              {!hasImage && !!mostRelevantOccasion && (
                <TearOffDate
                  date={mostRelevantOccasion.startDate}
                  css={css`
                    flex: none;
                    align-self: start;
                  `}
                />
              )}
              <div
                css={css`
                  display: grid;
                  row-gap: inherit;
                `}
              >
                <CardTitle {...titleProps} />
                <Typography variant="description">
                  <EventAttributeList
                    occasions={eventOccasions}
                    attributes={eventAttributes}
                    renderAttributeValue={(value) => <Html>{value}</Html>}
                    compactOccasions
                    spacing={2}
                  />
                </Typography>
                {/* <Typography variant="meta">{contentTypeLabel}</Typography> */}
              </div>
            </div>
          </CardContent>
        </Fragment>
      )}
    </Card>
  );
}
